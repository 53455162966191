import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import FirstDataLogo from "../images/partner-logos/first_data.svg"
import ChaseLogo from "../images/partner-logos/chase_paymentech.svg"
import BAMSLogo from "../images/partner-logos/bams.svg"
import WellsFargoLogo from "../images/partner-logos/wells_fargo-merchant_services.svg"
import ElavonLogo from "../images/partner-logos/elavon.svg"
import HeartlandLogo from "../images/partner-logos/heartland_payment_systems.svg"
import WorldpayLogo from "../images/partner-logos/worldpay.svg"
import GlobalPaymentsLogo from "../images/partner-logos/global_payments.svg"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl font-light text-gray-900 sm:text-4xl">
              About
              <span className="text-purple-700"> SmartPay 21</span>
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-500">
              We leverage the world's largest payment processors in order to provide you the highest level of security,
              scalability and functionality. Our experience provides you premium service and simple interfaces to these
              leading payment platforms.
            </p>
            <div className="mx-4 md:mx-0 mt-10 sm:flex sm:justify-center md:justify-start">
              <div className="rounded-md shadow">
                <Link to="/contact"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-700 hover:bg-purple-800 md:py-4 md:text-lg md:px-10">
                  Get started
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-12" src={FirstDataLogo} alt="First Data" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-12" src={ChaseLogo} alt="Chase Paymentech" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-12" src={BAMSLogo} alt="Bank of America Merchant Services" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-12" src={WellsFargoLogo} alt="Wells Fargo Merchant Services" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-12" src={ElavonLogo} alt="Elavon" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-12" src={HeartlandLogo} alt="Heartland Payment Systems" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-12" src={WorldpayLogo} alt="Worldpay" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-12" src={GlobalPaymentsLogo} alt="Global Payments" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

export default AboutPage
